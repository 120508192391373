import React from 'react'
import { PhoneTextInput } from 'mgr/lib/forms/TextInput'

const PortedPhoneTextInput = ({ disabled, label, placeholder, country, value, displayInitialValueAsLocalNumber }) => (
  <PhoneTextInput
    style={{ color: '#ccc' }}
    textStyle={{ color: '#000' }}
    disabled={disabled}
    label={label}
    placeholder={placeholder}
    country={country}
    value={value}
    displayInitialValueAsLocalNumber={displayInitialValueAsLocalNumber}
    onChange={() => null}
    onCountryChange={() => null}
  />
)

export default PortedPhoneTextInput
