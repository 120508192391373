import React from 'react'
import ReactDOM from 'react-dom'
import PortedMessageBox from './containers/PortedMessageBox'
import PortedPhoneTextInput from './containers/PortedPhoneTextInput'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { validatorFuncs, ValidatorTypes } from 'mgr/lib/forms/TextInput'

export const initPhonePickers = (phoneId, phoneNumVal, phoneNumLocale) => {
  ReactDOM.render(
    <PortedPhoneTextInput
      disabled={false}
      label=""
      placeholder=""
      country={phoneNumLocale}
      value={phoneNumVal}
      displayInitialValueAsLocalNumber={false}
    />,
    document.getElementById(phoneId)
  )
}

export const validatePhoneNumber = (val, countryCode) => validatorFuncs[ValidatorTypes.phoneRequired](val, countryCode)

export const initMessageBox = (modalId, proceed, cancel, dialogType, header, explanation, actionButtonText) => {
  ReactDOM.render(
    <PortedMessageBox
      proceed={proceed}
      cancel={cancel}
      dialogType={dialogType}
      header={header}
      explanation={explanation}
      actionButtonText={actionButtonText}
    />,
    document.getElementById(modalId)
  )
}

svrExport('PortedElements', 'initPhonePickers', initPhonePickers)
svrExport('PortedElements', 'validatePhoneNumber', validatePhoneNumber)
svrExport('PortedElements', 'initMessageBox', initMessageBox)
