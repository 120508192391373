import React from 'react'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

const PortedMessageBox = ({ proceed, cancel, dialogType, header, explanation, actionButtonText }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={dialogType}
    header={header}
    actionButtonText={actionButtonText}
    explanation={explanation}
  />
)

export default PortedMessageBox
